<template>
  <div class="home">
    <section class="hero is-fullheight landing-img">
      <div class="hero-body">
      </div>
      <div class="hero-foot">
        <div class="container">
          <h1 class="landing-title">{{ $t('home.vision') }}</h1>
          <h1 class="landing-title">{{ $t('home.inspection') }}</h1>
          <h2 class="landing-title landing-sub">{{ $t('home.system') }}</h2>
        </div>
        <img class="landing-overlay" src="@/images/img_landingpage_main_2.png" alt="">
      </div>
    </section>

    <div class="feature">
      <img class="landing-overlay2" src="@/images/img_landing_flash.png" alt="">
      <img class="landing-overlay3" src="@/images/img_landing_flash2.png" alt="">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="feature-content">
              <img class="feature-icon" src="@/images/icn_landing_strong.svg"/>
              <!-- <p class="feature-title">{{ $t('home.strong') }}</p> -->
              <h4 class="feature-txt">{{ $t('home.strongTxt') }}</h4>
            </div>
          </div>
          <div class="column">
            <div class="feature-content">
              <img class="feature-icon" src="@/images/icn_landing_completed.svg"/>
              <!-- <p class="feature-title">{{ $t('home.completed') }}</p> -->
              <h4 class="feature-txt">{{ $t('home.completedTxt') }}</h4>
            </div>
          </div>
          <div class="column">
            <div class="feature-content">
              <img class="feature-icon" src="@/images/icn_landging_efficient.svg"/>
              <!-- <p class="feature-title">{{ $t('home.efficient') }}</p> -->
              <h4 class="feature-txt">{{ $t('home.efficientTxt') }}</h4>
            </div>
          </div>
          <div class="column">
            <div class="feature-content">
              <img class="feature-icon" src="@/images/icn_landing_productive.svg"/>
              <!-- <p class="feature-title">{{ $t('home.productive') }}</p> -->
              <h4 class="feature-txt">{{ $t('home.productiveTxt') }}</h4>
            </div>
          </div>
        </div>
        <h1 class="looking-for-back">{{ $t('home.lookingFor1') }}</h1>
      </div>
    </div>

    <div class="looking-for">
      <div class="container">
        <div class="columns">
          <div class="column">
            <img class="looking-for-img" src="@/images/img_landing_camera.png" alt="">
          </div>
          <div class="column">
            <div class="looking-for-txt">
              <h1>{{ $t('home.lookingFor2') }}</h1>
              <h1>{{ $t('home.lookingFor3') }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="our-products">
      <div class="container">
        <h1 class="our-products-title">{{ $t('home.ourProducts') }}</h1>
        <div class="columns is-multiline">
          <div class="column is-one-quarter-desktop">
              <router-link :to="'/product?tab=warper'" class="product-bg warper">
                <h4 class="product-title">{{ $t('home.warper') }}</h4>
                <p class="product-txt">{{ $t('home.warperTxt') }}</p>
                <div class="btn-see-more">{{ $t('home.seeMore') }}</div>
              </router-link>
          </div>
          <div class="column is-one-quarter-desktop">
              <router-link :to="'/product?tab=ccl'" class="product-bg ccl">
                <h4 class="product-title">{{ $t('home.ccl') }}</h4>
                <p class="product-txt">{{ $t('home.cclTxt') }}</p>
                <div class="btn-see-more">{{ $t('home.seeMore') }}</div>
              </router-link>
          </div>
          <div class="column is-one-quarter-desktop">
              <router-link :to="'/product?tab=glass'" class="product-bg glass">
                <h4 class="product-title">{{ $t('home.glass') }}</h4>
                <p class="product-txt">{{ $t('home.glassTxt') }}</p>
                <div class="btn-see-more">{{ $t('home.seeMore') }}</div>
              </router-link>
          </div>
          <div class="column is-one-quarter-desktop">
              <router-link :to="'/product?tab=prepreg'" class="product-bg prepreg">
                <h4 class="product-title">{{ $t('home.prepreg') }}</h4>
                <p class="product-txt">{{ $t('home.prepregTxt') }}</p>
                <div class="btn-see-more">{{ $t('home.seeMore') }}</div>
              </router-link>
          </div>
        </div>
        <div class="customized">
          <div class="columns">
            <div class="column">
              <router-link :to="'/product?tab=customize'">
                <div class="customized-bg">
                  <h4 class="product-title">{{ $t('home.customized') }}</h4>
                  <p class="product-txt">{{ $t('home.customizedTxt') }}</p>
                  <div class="btn-see-more">{{ $t('home.seeMore') }}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="news">
      <div class="container">
        <h4 class="news-title">{{ $t('home.news') }}</h4>
        <div class="columns">
          <div class="column">
            <h4 class="news-content-title">{{ $t('home.newsTitle1') }}</h4>
            <p class="news-content-txt">{{ $t('home.newsTxt1') }}</p>
          </div>
          <div class="column">
            <img src="@/images/img_landing_news.jpg" alt="">
          </div>
        </div>
        <h4 class="news-content-title">{{ $t('home.newsTitle2') }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style lang="sass" scoped>
.home
  max-width: 100%
  .landing-img
    background-image: url('../images/img_landing_main.jpg')
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
  .hero-foot
    padding: 12rem 1.5rem 3rem
    .container
      z-index: 5
      .landing-title
        color: #005cb9
      .landing-sub
        color: #acbac0
  .landing-overlay
    position: absolute
    left: 0
    bottom: 0
    z-index: 2
    width: 120vh
  .landing-overlay2
    position: absolute
    left: 0
    margin-top: -20rem
    z-index: 3
  .landing-overlay3
    position: absolute
    right: 0
    margin-top: 14rem
    z-index: 3
  .feature
    padding: 12rem 0 0
    background-color: #1a191b
    margin-top: -1px
    .container
      min-height: 780px
      .feature-content
        max-width: 200px
        width: 100%
        margin: 0 auto
        text-align: center
      .feature-icon
        margin-bottom: 20px
      .feature-title
        color: #808080
      .feature-txt
        color: #ffffff
      .looking-for-back
        position: absolute
        bottom: 1.5rem
        right: 1.75rem
        color: #ffffff
  .looking-for
    background-color: #e4e4e4
    .looking-for-img
      max-width: 235%
      margin-top: -22rem
      margin-left: -3rem
    .looking-for-txt
      text-align: right
  .our-products
    background-color: #e4e4e4
    .our-products-title
      margin: -15rem 0 5rem
    .product-bg
      position: relative
      width: 270px
      height: 390px
      color: #ffffff
      margin: 0 auto
      padding: 0 11px 0 16px
      display: flex
      flex-wrap: wrap
      align-content: center
      background: url('../images/img_landing_product_1.png')
      background-position: top center
      background-repeat: no-repeat
      background-size: contain
      background-color: #000000
      &:hover,
      &:focus
        border: solid 8px #005cb9
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5)
        &::before
          border-left: 55px solid #005cb9
      &::before
        content: ""
        position: absolute
        top: -1px
        left: -1px
        width: 55px
        height: 0
        border-left: 55px solid #e4e4e4
        border-bottom: 70px solid transparent
    .ccl
      background: url('../images/img_landing_product_2.png')
      background-position: top center
      background-repeat: no-repeat
      background-size: contain
      background-color: #000000
    .glass
      background: url('../images/img_landing_product_3.png')
      background-position: top center
      background-repeat: no-repeat
      background-size: contain
      background-color: #000000
    .prepreg
      background: url('../images/img_landing_product_4.png')
      background-position: top center
      background-repeat: no-repeat
      background-size: contain
      background-color: #000000
    .product-title
      width: 100%
    .product-txt
      margin-top: 10px
    .btn-see-more
      position: absolute
      bottom: 25px
      right: 25px
      padding: 10px 13px
      background-color: #005cb9
    .customized
      padding: 2rem 0 10rem
      .customized-bg
        position: relative
        max-width: 800px
        width: 100%
        height: 150px
        color: #ffffff
        margin: 0 auto
        padding: 0 50px 0 50px
        display: flex
        flex-wrap: wrap
        align-content: center
        background: url('../images/img_landing_product_5.png')
        background-position: top right
        background-repeat: no-repeat
        background-size: contain
        background-color: #000000
        opacity: 0.8
        &:hover
          border: solid 8px #005cb9
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5)
  .news
    background-color: #ffffff
    padding: 5rem 0
    .news-title
      margin-bottom: 4rem
    .news-content-title
      color: #005cb9
      margin-bottom: 10px
    .news-content-txt
      max-width: 470px
      width: 100%
      text-align: justify
@media (min-width: 769px) and (max-width: 1023px)
  .home
    .hero-foot
      padding: 12rem 1.5rem 2rem
      .container
        z-index: 5
        margin-bottom: 3rem
    .feature
      .container
        min-height: 460px
        padding-bottom: 13rem
        .looking-for-back
          right: 6.75rem
        .column
          margin-bottom: 40px
    .looking-for
      .columns
        display: flex
        flex-direction: column-reverse
        margin-top: 0
      .looking-for-img
        margin-top: 0
@media (max-width: 768px)
  .home
    .landing-img
      background-position: 65% center
    .hero-foot
      position: relative
      overflow: hidden
      padding: 12rem 1.5rem 7rem
      .container
        padding: 1rem
    .landing-overlay
      max-width: none
      width: 45rem
    .feature
      .container
        padding-bottom: 13rem
        margin-top: -12rem
        .looking-for-back
          right: 2.75rem
          bottom: 1rem
          font-size: 1.9rem
        .column
          margin-bottom: 40px
    .looking-for
      position: relative
      overflow: hidden
      .columns
        display: flex
        flex-direction: column-reverse
        margin-top: 0
      .looking-for-img
        margin-top: 0
        margin-left: 0
      .looking-for-txt
        h1
          font-size: 1.9rem
    .our-products
      margin-top: 5rem
      .our-products-title
        font-size: 1.9rem
        text-align: center
      .customized
        .customized-bg
          width: 270px
          height: 390px
          padding: 0 11px 0 16px
</style>
